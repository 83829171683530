import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { DATA } from "../Livre/Data";
import { tools } from "@mitim/react-mitim";
import "./Home.scss";

const Livre = props => (
	<div className="col-md-4">
		<div className="livreBloc">
			<div className="bloc">
				<Link to={`/livre/${props.data.id}/${props.data.titre}`}>
					<img
						src={`${tools.lienCalvinEdition}/livres/${props.data.couverture}`}
						alt=""
						className="img-fluid"
					/>
					<div className="ombre"></div>
					<div className="texteLien">
						<span className="">VOIR PLUS</span>
					</div>
				</Link>
			</div>
			<div className={"texteBloc rouge " + props.data.couleurBloc}>
				<div className="titre">{props.data.titre}</div>
				<div className="sousTitre">{props.data.auteur}</div>
			</div>
		</div>
	</div>
);

class Home extends Page {
	render() {
		const { t } = this.props;

		return (
			<>
				<div
					className="container-fluid no-gutters pt-4 pb-5"
					style={{ backgroundColor: "#d9d9d9" }}
				>
					<div className="container pt-3 pb-5">
						<div className="row">
							<div className="col-md-6">
								<div className="p-3">
									<h1 style={{ color: "#000" }} className="ft-open">
										FOCUS
									</h1>
									<p>
										Maéli Dupont (1908-1991), de son vrai nom
										Marguerite-Élisabeth Dupays, est une artiste qui a été
										pianiste, élève de Hélène Krzyzanowska, puis compositrice
										(1er prix d’harmonie du Conservatoire de Paris), et, enfin,
										a voyagé beaucoup comme concertiste pour faire connaître les
										ondes Martenot, un instrument de musique électronique
										inventé par Maurice Martenot en 1928. En marge de la
										réédition de son récit autobiographique, Le chemin
										d'espérance, nous vous proposons d'écouter quelques-unes de
										ses interprétations aux ondes Martenot puis au piano. ©
										Archives privées
										<br />
										<br />
										00:00 Méditation, composition Maéli Dupont, Ondes Martenot
										<br />
										03:24 Musique folklorique, origine Amérique du Sud?, Ondes
										Martenot
										<br />
										04:50 Musique folklorique, origine Amérique du Sud?, Ondes
										Martenot
										<br />
										05:41 Nocturne, Chopin, Piano
										<br />
										08:45 Mazurkas, Piano
										{/* "Rien n'égalait l'ardeur que les amis de l'Evangile
										mettaient à lire et à répandre ce livre dans toute l'Italie,
										si ce n'est le zèle des inquisiteurs à le rechercher et à le
										livrer aux flammes." Louis Bonnet, 1858.
										<br />
										<Link
											to="/livre/2/Le%20bienfait%20de%20Jésus-Christ%20crucifié"
											style={{ color: "#414042" }}
										>
											<strong>
												<i>« Le bienfait de Jésus-Christ crucifié »</i>
											</strong>
										</Link> */}
										{/* De Colmar à Buchenwald, témoignage de Jean-Paul Kremer,
										déporté pour la foi. Propos recueillis par Stéphane Zehr et
										Jérémie Loiret (Calvin éditions). C'est à la fin de cet
										entretien que Jean-Paul Krémer nous a remis la manuscrit de
										son témoignage :{" "}
										<Link
											to="/livre/1/Le%20salut%20ne%20vient%20pas%20d’Hitler"
											style={{ color: "#414042" }}
										>
											<strong>
												<i>« Le salut ne vient pas d'Hitler »</i>
											</strong>
										</Link>
										. */}
									</p>
								</div>
							</div>
							<div className="col-md-6">
								<div className="video-container" style={{ top: "24px" }}>
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/ME9K09kcrDU"
										frameborder="0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									></iframe>
								</div>
							</div>
							{/* <div className="col-md-6">
								<div className="p-3">
									<h1 style={{ color: '#000' }} className="ft-open">
										FOCUS
								</h1>
									<p >
										Deux minutes pour découvrir en vidéo notre première parution <strong><i>« Au milieu de tant d'épreuves »</i></strong>.
								</p>
								</div>
							</div>
							<div className="col-md-6">
								<div className="video-container" style={{ top: '24px' }}>
									<iframe width="560" height="315" src="https://www.youtube.com/embed/Nr3foAIzKmA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
								</div>
							</div> */}
						</div>
					</div>
				</div>

				<div className="container-fluid pt-5 pb-5 no-gutters">
					<div className="container">
						<h1
							className="text-center text-uppercase"
							style={{ color: "#000" }}
						>
							Nouveautés
						</h1>
						<div className="row pt-5 pb-5 listeLivre">
							{/* A enlever apres !!! */}
							{/* <div className="col-md-2"></div> */}
							{/* ################ */}

							{/* {DATA.map((data, index) => <Livre data={data} />).reverse()} */}
							<Livre data={DATA[10]} />
							<Livre data={DATA[9]} />
							<Livre data={DATA[8]} />
							{/* <Livre data={DATA[7]} /> */}
							{/* <Livre data={DATA[6]} /> */}
							{/* <Livre data={DATA[5]} /> */}
							{/* <Livre data={DATA[4]} /> */}
							{/* <Livre data={DATA[3]} /> */}

							{/* <Livre data={DATA[2]} /> */}
							{/* <Livre data={DATA[1]} /> */}
							{/* A enlever apres !!! */}
							{/* <div className="col-md-2"></div> */}
							{/* ################ */}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(Home);
