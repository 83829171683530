import React from "react";

export const DATA = [
	{
		id: 0,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url: "Au milieu de tant d epreuve - 4 eme couverture.jpg"
			},
			{
				nom: "Préface",
				url: "Au milieu de tant d epreuve - preface.pdf"
			},
			{
				nom: "Table des matières",
				url: "Au milieu de tant d epreuve - table des matieres.pdf"
			}
		],
		prix: "10",
		couleurBloc: "bleu",
		couverture: "Au milieu de tant d epreuve - Couverture.jpg",
		titre: "Au milieu de tant d'épreuves",
		sousTitre: "Lettres de consolation",
		collection: "Réforme et réveil",
		dateParution: "06/07/2020",
		isbn: "978-2-492099-007",
		ean: "9782492099007",
		auteur: "Jean Calvin, Paul Wells (préface)",
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "160",
		poids: "158g",
		dimensions: "12cm x 19cm",
		epaisseur: "13mm",
		lienVideoYoutube: "https://www.youtube.com/embed/Nr3foAIzKmA",
		resume: (
			<>
				<p>
					Jean Calvin le réformateur fut avant tout un pasteur. Sa volumineuse
					correspondance en témoigne : il écrit à des hommes et des femmes de
					toutes conditions pour les exhorter à la foi. Ses lettres de direction
					spirituelle, qui impliquent une relation personnelle affectueuse avec
					ses destinataires, sont ainsi centrées sur l’édification et la
					consolation. Elles sont aussi un moyen important pour la diffusion de
					la doctrine réformée.
				</p>
				<p>
					Voilà pourquoi nous avons souhaité proposer au lecteur d’aujourd’hui
					cette sélection de 15 lettres traduites en français contemporain,
					introduites et annotées. Leur intérêt dépasse en effet les
					circonstances historiques de leur rédaction. Écrites pour consoler les
					croyants « sous la Croix », elles peuvent encore servir à ceux qui
					sont affligés ainsi qu’à ceux qui veulent les aider.
				</p>
			</>
		),
		extendedResume: false
	},

	{
		id: 1,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url: "Le salut ne vient pas d Hitler - 4eme-de-couv.jpg"
			},
			{
				nom: "Préface",
				url: "Le salut ne vient pas d Hitler - preface.pdf"
			},
			{
				nom: "Présentation de la première édition",
				url: "Le salut ne vient pas d Hitler - pres-premiere-edition.pdf"
			}
		],
		prix: "13,90",
		couleurBloc: "jaune",
		couverture: "Le salut ne vient pas d Hitler - Couverture.jpg",
		titre: "Le salut ne vient pas d’Hitler",
		sousTitre: (
			<>
				Un mennonite déporté à Natzweiler
				<br /> et Buchenwald
			</>
		),
		collection: "Témoins",
		dateParution: "17/09/2020",
		isbn: "978-2-492099-014",
		ean: "9782492099014",
		auteur: (
			<>
				Jean-Paul Krémer,
				<br /> Frédérique Neau-Dufour (préface)
			</>
		),
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "264",
		poids: "286g",
		dimensions: "13,5cm x 21cm",
		epaisseur: "18mm",
		lienVideoYoutube: "https://www.youtube.com/embed/gZ1lHL-guhM",
		// lienVideoYoutube: 'https://www.youtube.com/embed/Nr3foAIzKmA',
		resume: (
			<>
				<p>
					De Colmar à Buchenwald, en passant par le KL Natzweiler-Struthof,
					Jean-Paul Krémer témoigne de son expérience concentrationnaire. Agé de
					dix-sept ans, il refuse le salut hitlérien au lycée, la participation
					aux Jeunesses Hitlériennes et l’incorporation dans la Wehrmacht.
					Commence alors le cycle infernal de la répression, accepté par
					fidélité à ses convictions chrétiennes et traversé grâce au soutien de
					sa foi. Un document exceptionnel.
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 2,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url: "Le bienfait de Jesus-Christ crucifie - 4 eme de Couverture.jpg"
			},
			{
				nom: "Préface",
				url: "Le bienfait de Jesus-Christ crucifie - Preface.pdf"
			}
		],
		prix: "10",
		couleurBloc: "bleu",
		couverture: "Le bienfait de Jesus-Christ crucifie - Couverture.jpg",
		titre: "Le bienfait de Jésus-Christ crucifié",
		sousTitre: (
			<>
				Un texte rescapé <br />
				des flammes de l'inquisition
			</>
		),
		collection: "Réforme & Réveil",
		dateParution: "03/05/21",
		isbn: "978-2-492099-038",
		ean: "9782492099038",
		auteur: (
			<>
				Benedetto da Mantova, Marcantonio Flaminio
				<br />
				Pietro Bolognesi (préface)
			</>
		),
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "168",
		poids: "168g",
		dimensions: "12cm x 19cm",
		epaisseur: "13mm",
		lienVideoYoutube: "https://www.youtube.com/embed/VfkKd8SkNdk",
		resume: (
			<>
				<p>
					Le bienfait de Jésus-Christ crucifié est un chef-d'œuvre de la Réforme
					italienne. Traqué par l'inquisition, rapidement traduit en français,
					réédité au moment du Réveil par Louis Bonnet, il expose ce qu'est la
					justification par la foi. C'est un texte enthousiaste et plein de
					force, nourri de Luther et de Calvin, qui fera sentir au lecteur toute
					l'actualité du message de la grâce.
					<br /> "Rien n'égalait l'ardeur que les amis de l'Evangile mettaient à
					lire et à répandre ce livre dans toute l'Italie, si ce n'est le zèle
					des inquisiteurs à le rechercher et à le livrer aux flammes." Louis
					Bonnet, 1858.
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 3,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url: "Chretiennes meditations - 4 eme Couverture.jpg"
			},
			{
				nom: "Préface originale",
				url: "Chretiennes meditations - Preface originale.pdf"
			},
			{
				nom: "Préface d'Alain-Cyril Barioz",
				url: "Chretiennes meditations - Preface Barioz.pdf"
			}
		],
		prix: "10",
		couleurBloc: "bleu",
		couverture: "Chretiennes meditations - Couverture.jpg",
		titre: "Chrétiennes méditations",
		sousTitre: <>Les psaumes pénitentiels</>,
		collection: "Réforme & Réveil",
		dateParution: "30/09/21",
		isbn: "978-2-492099-045",
		ean: "9782492099045",
		auteur: (
			<>
				Théodore de Bèze
				<br />
				Alain-Cyril Barioz (préface)
			</>
		),
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "144",
		poids: "140g",
		dimensions: "12cm x 19cm",
		epaisseur: "12mm",
		//lienVideoYoutube: "https://www.youtube.com/embed/VfkKd8SkNdk",
		resume: (
			<>
				<p>
					Un des principaux mérites de la Réforme en Suisse et en France est
					d'avoir mis la Bible, et en particulier le livre des Psaumes, au cœur
					de la piété personnelle des croyants. Théodore de Bèze (1519-1605),
					sans doute le plus proche des compagnons de Calvin, a beaucoup
					contribué à la traduction des Écritures, en prose comme en vers.
					<br />
					<br />
					Les Chrétiennes méditations (1581) se situent au croisement des
					talents littéraires et théologiques de leur auteur. À partir de huit
					psaumes choisis (le psaume 1 et les sept psaumes dits
					traditionnellement pénitentiels), Théodore de Bèze renouvelle le genre
					ancien de la méditation. Si la dimension autobiographique est souvent
					sensible, l'itinéraire de repentance et de conversion qu'il propose
					peut néanmoins parler à chacun.
					<br />
					<br />
					Cette édition présente le texte de Bèze en français modernisé et le
					situe dans le contexte littéraire de l'époque. Le lecteur est ainsi à
					même d'apprécier les qualités esthétiques et doctrinales d'un des plus
					beaux exemples de la spiritualité du protestantisme réformé.
					<br />
					<br />
					Adaptation du texte original par Arthur Laisis et Caleb Abraham
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 4,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url: "Huguenot galerien et martyr - 4 eme Couverture.jpg"
			},
			{
				nom: "Avant-propos de Denis Vatinel",
				url: "Huguenot galerien et martyr - Avant propos.pdf"
			}
		],
		prix: "13,90",
		couleurBloc: "jaune",
		couverture: "Huguenot galerien et martyr - Couverture.jpg",
		titre: "Huguenot, galérien et martyr",
		sousTitre: <>Histoire des malheurs d'Isaac Le Febvre</>,
		collection: "Témoins",
		dateParution: "07/12/2021",
		isbn: "978-2-492099-021",
		ean: "9782492099021",
		auteur: (
			<>
				Etienne Girard
				<br />
				Denis Vatinel (avant-propos)
			</>
		),
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "328",
		poids: "340g",
		dimensions: "13.5cm x 21cm",
		epaisseur: "23mm",
		//lienVideoYoutube: "https://www.youtube.com/embed/VfkKd8SkNdk",
		resume: (
			<>
				<p>
					Le témoignage d'Isaac Le Febvre est un des documents les plus précieux
					sur la vie des protestants français condamnés aux galères sous le
					règne de Louis XIV, coupables aux yeux du roi d’avoir assisté à une
					assemblée de culte interdite ou d’avoir tenté de quitter
					clandestinement le royaume.
					<br />
					<br />
					Jules Bonnet écrivait en 1882 dans le Bulletin de la Société de
					l’Histoire du Protestantisme Français :
					<br />
					Voici un de ces volumes que l’on ne peut ouvrir sans respect ni fermer
					sans émotion, véritable joyau de notre littérature réformée, où l’art
					disparaît pour laisser parler l’âme seule dans la sainteté de ses
					douleurs et la sublimité de ses sacrifices ; héroïsme bien supérieur à
					celui des champs de bataille et qui serait demeuré inconnu au siècle
					du grand roi, s’il n’eut pris soin d’en attiser la flamme au foyer
					dévasté de Port-Royal et de la Réforme. Autre cependant est la vertu
					qui se déploie au fond des cloîtres, autre celle qui s’exerce sur le
					banc des galères, et qui arrache ce cri d’admiration à un éloquent
					historien : « Oh ! Noble société que celle des galères. Il semble que
					toute vertu s’y fut réfugiée. Obscur ailleurs, là Dieu était visible.
					C’est là qu’il eut fallu amener toute la terre. »
					<br />
					<br />
					Avant-propos de Denis Vatinel, pasteur de l'Eglise protestante unie de
					France et conservateur du musée régional d'Histoire protestante du
					Bois-Tiffrais en Vendée.
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 5,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url: "Memoires de mes fantomes - 4 eme Couverture.jpg"
			}
		],
		prix: "35",
		couleurBloc: "rouge",
		couverture: "Memoires de mes fantomes - Couverture.jpg",
		titre: "Mémoires de mes fantômes",
		sousTitre: <>Ami Bost - John Bost - Les descendants</>,
		collection: "Histoire & Mémoire",
		dateParution: "25/02/2022",
		isbn: "978-2-492099-052",
		ean: "9782492099052",
		auteur: <>Charles-Marc Bost</>,
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "1020",
		poids: "1230g",
		dimensions: "16,3cm x 24cm",
		epaisseur: "44mm",
		//lienVideoYoutube: "https://www.youtube.com/embed/VfkKd8SkNdk",
		resume: (
			<>
				<p>
					Saga protestante, histoire du Réveil et roman-fleuve, Mémoires de mes
					fantômes retrace, à sa façon personnelle et puissamment évocatrice,
					les destinées de la famille Bost.
					<br />
					Laissant une grande part à la parole des acteurs, Charles-Marc Bost
					retrace la vie d'Ami, père de la lignée, "enfant terrible" du Réveil
					et celle de John, son fils, fondateur des Asiles de La Force et
					pionnier de la prise en charge du handicap. La saga est aussi celle
					des descendants parmi lesquels on trouve des pasteurs et des
					historiens (Charles Bost), des pionniers de l'Ouest américain
					(Théodore Bost), des écrivains et des artistes (Renaud). Des "dix
					fils" d'Ami est née une immense famille répartie sur plusieurs
					continents.
					<br />
					Parue en trois tomes (Ami et ses dix fils - John - Les descendants)
					l’œuvre de Charles-Marc Bost est aujourd'hui rééditée en un volume
					dans la collection "Histoire et Mémoire".
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 6,
		fichiers: [
			{
				nom: "Quatrième de couverture", //
				url: "Le chemin d esperance - 4 eme Couverture.jpg"
			},
			{
				nom: "Introduction de Stéphane Zehr", //
				url: "Le chemin d esperance - Introduction.pdf"
			}
		],
		prix: "13,90", //
		couleurBloc: "jaune", //
		couverture: "Le chemin d esperance - Couverture.jpg",
		titre: "Le chemin d'espérance", //
		sousTitre: (
			<>
				Musique, occultisme et rédemption <br />
				dans la France du XXe siècle
			</>
		), //
		collection: "Témoins", //
		dateParution: "16/06/2022", //
		isbn: "978-2-492099-069", //
		ean: "9782492099069", //
		auteur: <>Maéli Dupont</>, //
		editeur: "Calvin Éditions", //
		typeReliure: "broché", //
		nombrePages: "336", //
		poids: "340g", //
		dimensions: "13,5cm x 21cm", //
		epaisseur: "23mm", //
		lienVideoYoutube: "https://www.youtube.com/embed/ME9K09kcrDU",
		resume: (
			<>
				<p>
					<span className="">
						“Il faut avoir vécu des jours douloureux comme nous en avions vécu,
						livrés à ce que nous pensions être un destin malchanceux contre
						lequel on ne peut rien, pour réaliser la valeur d’un secours
						spirituel inespéré.”
					</span>
					<br />
					<br />
					Maéli Dupont (1908-1991), ancienne résistante, a été musicienne,
					compositrice proche de Ravel et ambassadrice des Ondes Martenot (l’un
					des premiers instruments de musique électronique). Sa vie est d’abord
					placée sous le signe du voyage et de la réussite, puis marquée par des
					drames : mauvais mariages, déclassement, misère, angoisses liées à la
					pratique de l’art et de l’occultisme.
					<br />
					Son témoignage révèle une personnalité haute en couleur ainsi que
					l’envers de la vie d’artiste. Récit de femme, il donne surtout un
					visage à la France reléguée des années 1950-1960, “l’autre côté du
					paradis” des prétendues “Trente glorieuses”.
					<br />
					Pourtant, ce “destin malchanceux” la rend sensible à l’espérance de
					l’Évangile, lorsqu’elle lui est présentée par des missionnaires qui
					frappent à sa porte. Cette rencontre est l’occasion d’un nouveau
					départ.
					<br />
					<br />
					Introduction de Stéphane Zehr
					<br />
					<br />
					Suivi de : Musique que choisir ? Essai sur la relation entre la
					musique, la magie et le surnaturel à travers le monde, par Maéli
					Dupont.
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 7,
		fichiers: [
			{
				nom: "Quatrième de couverture", //
				url: "Le Dieu du Reveil - 4 eme de Couverture.jpg"
			},
			{
				nom: "Introduction de Stéphane Zehr", //
				url: "Le Dieu du Reveil - Introduction.pdf"
			}
		],
		prix: "13,50", //
		couleurBloc: "bleu", //
		couverture: "Le Dieu du Reveil - Couverture.jpg",
		titre: "Le Dieu du Réveil", //
		sousTitre: <>Sermons choisis de la Brigade de la Drôme (1922-1939)</>, //
		collection: "Réforme & Réveil", //
		dateParution: "20/12/2022", //
		isbn: "978-2-492099-07-6", //
		ean: "9782492099076", //
		auteur: <>La brigade de la Drôme</>, //
		editeur: "Calvin Éditions", //
		typeReliure: "broché", //
		nombrePages: "224", //
		poids: "220g", //
		dimensions: "12cm x 19cm", //
		epaisseur: "18mm", //
		// lienVideoYoutube: "https://www.youtube.com/embed/ME9K09kcrDU",
		resume: (
			<>
				<p>
					« Dieu d’abord ! » Ce cri de ralliement des brigadiers compte parmi
					les vifs « coups d’éperon » portés à l’assoupissement religieux. Non !
					« Dieu ne se contente pas de ce que nous sommes ! » Au sortir de la
					Grande Guerre, tandis que les esprits se livrent à la fête et l’oubli,
					que les oreilles sont sourdes au discours religieux, un groupe de
					jeunes pasteurs de la Drôme fait du Réveil l’objet de ses prières.
					Celui-ci démarre en 1922. Dès lors, on ne peut arrêter ce feu qui
					embrase, paroisse après paroisse, la vallée de la Drôme. Ce recueil
					réunit de manière inédite quelques-uns des sermons poignants et
					incisifs de ce Réveil marqué par la redécouverte de Calvin.
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 8,
		fichiers: [
			{
				nom: "Quatrième de couverture", //
				url: "Captive des indiens - 4 eme Couverture.jpg"
			},
			{
				nom: "Préface de Bernard Cottret", //
				url: "Captive des indiens - Preface.pdf"
			}
		],
		prix: "13,90", //
		couleurBloc: "jaune", //
		couverture: "Captive des indiens - Couverture.jpg",
		titre: "Captive des indiens", //
		sousTitre: (
			<>
				Récit d'une puritaine <br />
				de Nouvelle-Angleterre <br />
				enlevée en 1675
			</>
		), //
		collection: "Témoins", //
		dateParution: "13/03/2023", //
		isbn: "978-2-492099-083", //
		ean: "9782492099083", //
		auteur: (
			<>
				Mary Rowlandson,
				<br /> Ada Savin (présentation)
			</>
		), //
		editeur: "Calvin Éditions", //
		typeReliure: "broché", //
		nombrePages: "192", //
		poids: "220g", //
		dimensions: "13,5cm x 21cm", //
		epaisseur: "13mm", //
		resume: (
			<>
				<p>
					Le 10 février 1675, en pleine guerre entre les colons anglais
					puritains et les tribus indiennes de Nouvelle-Angleterre, Mary
					Rowlandson, femme de pasteur, est enlevée avec ses enfants à l'issue
					d'une attaque sur la ville de Lancaster. Prisonnière de la tribu des
					Narragansetts, elle va s'enfoncer dans un pays encore sauvage durant
					onze semaines, en proie au froid et à la faim. C'est après bien des
					malheurs (à ses yeux autant d'épreuves envoyées par Dieu) que Mary,
					échangée contre une rançon, retrouvera son mari.
				</p>
				<p>
					Ce récit, écrit après sa délivrance et publié en 1682, constitue un
					document irremplaçable sur la vie des Amérindiens de cette deuxième
					moitié du XVIIe siècle : coutumes, habitat, nourriture, guerres ...
					Best-seller de la littérature américaine, il connut une longue et
					riche postérité. Il nous donne à entendre une des premières voix de
					femme venues du Nouveau Monde.
				</p>
				<p>
					Présentation et notes de Ada Savin, maître de conférences d'études
					américaines à l'université de Versailles Préface de Bernard Cottret (†
					2020), professeur d'université et membre de l'Institut de recherche
					sur les civilisations de l'Occident moderne Traduction de Mariette
					Broussous.
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 9,
		fichiers: [
			{
				nom: "Quatrième de couverture", //
				url: "Histoire de la Mission Timothee - 4 eme couverture.jpg"
			},
			{
				nom: "Préface de Pierre-Yves Kirschleger", //
				url: "Histoire de la Mission Timothee - Preface.pdf"
			}
		],
		prix: "23,90", //
		couleurBloc: "rouge", //
		couverture: "Histoire de la Mission Timothee - Couverture.jpg",
		titre: "Histoire de la Mission Timothée", //
		sousTitre: (
			<>
				Les jours des petits commencements
				<br />
				(1972-1986)
			</>
		), //
		collection: "Histoire & Mémoire", //
		dateParution: "29/05/2023", //
		isbn: "978-2-492099-090", //
		ean: "9782492099090", //
		auteur: (
			<>
				Stéphane Zehr,
				<br /> Pierre-Yves Kirschleger (préface)
			</>
		), //
		editeur: "Calvin Éditions", //
		typeReliure: "broché", //
		nombrePages: "328", //
		poids: "810g", //
		dimensions: "16,3cm x 24cm", //
		epaisseur: "18mm", //
		resume: (
			<>
				<p>
					La Mission Timothée tient une place à part au sein de la « nouvelle
					France protestante » du XXIe siècle. Née en Cévennes dans les
					seventies, elle s'organise autour d'une communauté et d'une maison qui
					accueille, en particulier, marginaux et jeunes en difficulté.
				</p>
				<p>
					Elle demeure pourtant méconnue. L'auteur situe ici les premiers
					développements de la Mission Timothée dans le contexte de crise des
					années 1960-1980. Entre histoire religieuse et histoire sociale, il en
					analyse les spécificités : accompagnement pastoral, fraternité et aide
					aux toxicomanes sur fond de démocratisation de l'usage des drogues ;
					vie de famille et morale sexuelle en un temps de libération des mœurs,
					création de cantiques face à l'essor du « rock chrétien » ; force de
					témoignage dans une société de plus en plus sécularisée.
				</p>
				<p>
					Dans ce récit très documenté, une histoire inédite se dessine, qui est
					aussi une histoire autre du protestantisme évangélique contemporain.
				</p>
				<p>
					Stéphane Zehr est pasteur de la Mission Timothée à Générargues et
					libraire de la librairie Jean Calvin à Alès
				</p>
			</>
		),
		extendedResume: false
	},
	{
		id: 10,
		fichiers: [
			{
				nom: "Quatrième de couverture", //
				url: "Malachie - 4 eme.jpg"
			}
		],
		prix: "17,90", //
		couleurBloc: "rouge", //
		couverture: "Malachie - couverture.jpg",
		titre: "Commentaire de Malachie", //
		sousTitre: <></>, //
		collection: "-", //
		dateParution: "24/04/2024", //
		isbn: "978-2-492099-10-6", //
		ean: "9782492099106", //
		auteur: (
			<>
				Jean Calvin,
				<br /> Renaud Festal (traduction)
			</>
		), //
		editeur: "Calvin Éditions et BLF Éditions", //
		typeReliure: "broché", //
		nombrePages: "180", //
		poids: "199g", //
		dimensions: "15cm x 22,5cm", //
		epaisseur: "18mm", //
		resume: (
			<>
				<p class="bleu">
					Prix de lancement : 17,90 euros jusqu'au 30/06/2024 puis 21,90 euros
				</p>
				<p>
					Ce volume est une retranscription fidèle des leçons que Jean Calvin
					donnait aux étudiants de Genève. Les deux ou trois dernières leçons ne
					sont pas publiques, mais prises en notes dans sa chambre lors d’un
					épisode de maladie (hiver 1558-1559).
				</p>
				<p>
					Initialement écrites en latin, langue dans laquelle Jean Calvin
					enseignait ses étudiants, elles paraissent aujourd’hui traduites en
					français pour la première fois et sont présentées conjointement par
					les éditions BLF et CALVIN. Le désir des éditeurs est que ce texte
					soit disponible pour le plus grand nombre et qu’il contribue à
					l’édification de l’Église.
				</p>
			</>
		),
		extendedResume: true
	}
];
