import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { tools } from "@mitim/react-mitim";
import BanniereCatalogue from "./BanniereCatalogue";
import { DATA } from "../Livre/Data";

const Livre = props => (
	<div className="col-md-4">
		<div className="livreBloc">
			<div className="bloc">
				<Link to={`/livre/${props.data.id}/${props.data.titre}`}>
					<img
						src={`${tools.lienCalvinEdition}/livres/${props.data.couverture}`}
						alt=""
						className="img-fluid"
					/>
					<div className="ombre"></div>
					<div className="texteLien">
						<span className="">VOIR PLUS</span>
					</div>
				</Link>
			</div>
			<div className={"texteBloc rouge " + props.data.couleurBloc}>
				<div className="titre">{props.data.titre}</div>
				<div className="sousTitre">{props.data.auteur}</div>
			</div>
		</div>
	</div>
);

class Temoins extends Page {
	render() {
		const { t } = this.props;

		return (
			<>
				<BanniereCatalogue
					titre={<>Témoins</>}
					paragraphe={
						<>
							Cette collection donne la parole à des hommes et des femmes qui
							ont été des témoins de la foi dans l’Histoire. Par leur vie et par
							leur(s) texte(s), ils ont incarné un message d’espérance qui nous
							parle encore aujourd’hui.
						</>
					}
					couleur="jaune"
					liens={{
						lien1: {
							texte: "Histoire & mémoire",
							to: "/catalogue/histoire-et-memoire"
						},
						lien2: {
							texte: "Réforme & réveil",
							to: "/catalogue/reforme-et-reveil"
						}
					}}
				/>
				<div className="container-fluid pt-5 pb-5 no-gutters">
					<div className="container">
						{/* <h1 className="text-center text-uppercase" style={{ color: '#000' }} >Nouveautés</h1> */}
						<div className="row pt-5 pb-5 listeLivre">
							{/* {DATA.map((data, index) => (<Livre data={data} />))} */}

							{/* A enlever apres !!! */}
							{/* ################ */}

							{/* {DATA.map((data, index) => (<Livre data={data} />))} */}

							<Livre data={DATA[8]} />
							<Livre data={DATA[6]} />
							<Livre data={DATA[4]} />

							<div className="col-md-4"></div>
							<Livre data={DATA[1]} />
							<div className="col-md-4"></div>
							{/* A enlever apres !!! */}
							{/* ################ */}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(Temoins);
